/*
 * @Author: mengying.yao
 * @Email: mengying.yao@medbanks.cn
 * @Date: 2020-09-14 14:57:16
 * @Last Modified by: shuwen.chen
 * @Last Modified time: 2020-12-30 17:06:02
 * @Description: Description
 * @Route: Route
 */


<template>
  <div class="question-list">
    <div>
      <h3 class="header_name">
        详情查看
      </h3>
      <p class="search_info">
        <span>{{ queryParam.channelName }}</span>
        <span v-if="queryParam.channelName2"> - {{ queryParam.channelName2 }}</span>
        <span v-if="queryParam.channelName3"> - {{ queryParam.channelName3 }}</span>
        <span v-if="queryParam.classificaName"> > {{ queryParam.classificaName }} </span>
        <span v-if="queryParam.labelName"> > {{ queryParam.labelName }}</span>
        <span v-if="queryParam.titles">
          <span class="line">|</span>
          当前搜索关键词：{{ queryParam.titles }}
        </span>
      </p>
    </div>

    <el-form ref="form"
             label-width="100px"
             class="questionForm">
      <el-form-item label="内部编号：">
        {{ questionData.internalCode }}
      </el-form-item>

      <el-form-item label="问题标题：">
        {{ questionData.title }}
      </el-form-item>
      <el-form-item label="答复：">
        <div v-html=" questionData.reply" />
      </el-form-item>
      <el-form-item label="所属分类："
                    class="line_top">
        {{ questionData.classificaName }}
      </el-form-item>
      <el-form-item label="切换状态：">
        {{ questionData.switchState === 1 ? "关闭" : "开启" }}
      </el-form-item>
      <el-form-item label="标签：">
        {{ questionData.labelName }}
      </el-form-item>

      <el-form-item label="业务来源："
                    prop="relationOriginProblemList">
        {{ questionData.channelName }}
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { question } from '@/api';

export default {
  data() {
    return {
      queryParam: this.$route.query,
      classList: [],
      sourceList: [],
      questionData: {
        classificaCode: '',
        switchState: '',
        title: '',
        labelList: [],
        reply: '',
        relationOriginProblemList: [],
      },
    };
  },

  created() {
    if (this.$route.query.id) {
      question
        .getQuestionDetail({
          id: this.$route.query.id,
        })
        .then((res) => {
          this.questionData = res.data;
        })
        .catch()
    } else {
      this.$message.warning('链接可能有错误');
    }
    question.getClassList().then((res) => {
      this.classList = res.data.data;
    });

    question.getOriginList().then((res) => {
      this.sourceList = res.data;
    });
  },
};
</script>

<style lang="scss" scoped>
.questionForm {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px;
  height: calc(100vh - 200px);
  overflow-x: scroll;
  /deep/ .el-form-item {
    width: 50%;
  }
}

.title {
  margin-top: 20px;
}
.header_name {
  background: #fff;
  padding: 5px 32px 15px;
}
.line_top {
  border-top: 1px solid#e9e9e9;
  padding-top: 10px;
}
.search_info {
  background: #fff;
  padding: 5px 32px 15px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  .line {
    margin-left: 5px;
  }
}
</style>
